




















































































































import {Component, Vue} from 'vue-property-decorator';
import AutoGenRegister from "@/components/auth/AutoGenRegister.vue";

@Component({
  components: {AutoGenRegister,},
})
export default class LandingPageIndex extends Vue {

}
